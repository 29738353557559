const aura = [
    [0, 0],
    [0.2, 70],
    [0.211, 76],
    [0.222, 82],
    [0.233, 88],
    [0.244, 94],
    [0.256, 101],
    [0.268, 108],
    [0.281, 116],
    [0.295, 125],
    [0.31, 135],
    [0.326, 146],
    [0.343, 158],
    [0.361, 171],
    [0.38, 185],
    [0.4, 200],
    [0.42, 215],
    [0.44, 230],
    [0.46, 245],
    [0.48, 260],
    [0.5, 275],
    [0.52, 290],
    [0.54, 305],
    [0.56, 320],
    [0.58, 335],
    [0.6, 350],
    [0.62, 365],
    [0.64, 380],
    [0.66, 395],
    [0.68, 410],
    [0.7, 425],
    [0.72, 440],
    [0.74, 455],
    [0.76, 470],
    [0.78, 485],
    [0.8, 500],
    [0.82, 515],
    [0.84, 530],
    [0.86, 545],
    [0.88, 560],
    [0.9, 575],
];

const hp = [
    { name: "0 Ignore", hp: [1] },
    {
        name: "Archer Queen",
        hp: [
            85, 725, 740, 755, 771, 787, 804, 821, 838, 856, 874, 892, 911, 930,
            949, 969, 990, 1010, 1032, 1053, 1076, 1098, 1121, 1145, 1169, 1193,
            1218, 1244, 1270, 1297, 1324, 1352, 1380, 1409, 1439, 1469, 1500,
            1532, 1564, 1597, 1630, 1664, 1699, 1735, 1771, 1809, 1847, 1885,
            1925, 1965, 2007, 2058, 2110, 2163, 2218, 2274, 2331, 2390, 2450,
            2512, 2575, 2639, 2705, 2773, 2842, 2913, 2980, 3040, 3095, 3145,
            3190, 3230, 3270, 3310, 3350, 3390, 3425, 3460, 3495, 3530, 3565,
            3600, 3630, 3660, 3690, 3720,
        ],
    },
    {
        name: "Barbarian King",
        hp: [
            85, 1700, 1742, 1786, 1830, 1876, 1923, 1971, 2020, 2071, 2123,
            2176, 2230, 2286, 2343, 2402, 2462, 2523, 2586, 2651, 2717, 2785,
            2855, 2926, 2999, 3074, 3151, 3230, 3311, 3394, 3478, 3565, 3655,
            3746, 3840, 3936, 4034, 4135, 4238, 4344, 4453, 4564, 4678, 4795,
            4915, 5038, 5164, 5293, 5425, 5561, 5700, 5843, 5990, 6140, 6294,
            6452, 6614, 6780, 6950, 7124, 7303, 7486, 7673, 7865, 8062, 8264,
            8470, 8680, 8890, 9100, 9300, 9500, 9700, 9900, 10100, 10300, 10490,
            10680, 10870, 11060, 11250, 11400, 11550, 11700, 11850, 12000,
        ],
    },
    {
        name: "Royal Champion",
        hp: [
            35, 2950, 3000, 3050, 3100, 3150, 3200, 3250, 3300, 3350, 3400,
            3450, 3500, 3550, 3600, 3650, 3700, 3750, 3800, 3850, 3900, 3940,
            3980, 4020, 4060, 4100, 4140, 4180, 4220, 4260, 4300, 4330, 4360,
            4390, 4420, 4450,
        ],
    },
    {
        name: "Grand Warden",
        hp: [
            60, 1000, 1021, 1042, 1064, 1086, 1108, 1131, 1155, 1180, 1206,
            1233, 1261, 1290, 1320, 1350, 1380, 1410, 1440, 1470, 1500, 1530,
            1561, 1593, 1625, 1658, 1692, 1726, 1761, 1797, 1833, 1870, 1908,
            1947, 1986, 2026, 2067, 2109, 2152, 2196, 2240, 2260, 2280, 2300,
            2320, 2340, 2360, 2380, 2400, 2420, 2440, 2460, 2480, 2500, 2520,
            2540, 2560, 2580, 2600, 2620, 2640,
        ],
    },
    {
        name: "Eagle Artillery",
        hp: [5, 4000, 4400, 4800, 5200, 5600],
    },
    {
        name: "Scattershot",
        hp: [3, 3600, 4200, 4800],
    },
    {
        name: "Inferno Tower",
        hp: [9, 1500, 1800, 2100, 2400, 2700, 3000, 3300, 3700, 4000],
    },
    {
        name: "X-Bow",
        hp: [10, 1500, 1900, 2300, 2700, 3100, 3500, 3900, 4200, 4500, 4700],
    },
    {
        name: "Air Defense",
        hp: [
            13, 800, 850, 900, 950, 1000, 1050, 1100, 1210, 1300, 1400, 1500,
            1650, 1750,
        ],
    },
    {
        name: "Air Sweeper",
        hp: [7, 750, 800, 850, 900, 950, 1000, 1050],
    },
    {
        name: "Wizard Tower",
        hp: [
            15, 620, 650, 680, 730, 840, 960, 1200, 1440, 1680, 2000, 2240,
            2480, 2700, 2900, 3000,
        ],
    },
    {
        name: "Hidden Tesla",
        hp: [
            13, 600, 630, 660, 690, 730, 770, 810, 850, 900, 980, 1100, 1200,
            1350,
        ],
    },
    {
        name: "Builder's Hut",
        hp: [4, 250, 1000, 1300, 1600],
    },
    {
        name: "Archer Tower",
        hp: [
            21, 380, 420, 460, 500, 540, 580, 630, 690, 750, 810, 890, 970,
            1050, 1130, 1230, 1330, 1410, 1510, 1600, 1700, 1800,
        ],
    },
    {
        name: "Cannon",
        hp: [
            21, 420, 470, 520, 570, 620, 670, 730, 800, 880, 960, 1060, 1160,
            1260, 1380, 1500, 1620, 1740, 1870, 2000, 2150, 2250,
        ],
    },
    {
        name: "Bomb Tower",
        hp: [10, 650, 700, 750, 850, 1050, 1300, 1600, 1900, 2300, 2500],
    },
    {
        name: "Mortar",
        hp: [
            15, 400, 450, 500, 550, 600, 650, 700, 800, 950, 1100, 1300, 1500,
            1700, 1950, 2150,
        ],
    },
    {
        name: "Spell Tower",
        hp: [
            3, 2500, 2800, 3100,
        ],
    },
    {
        name: "Monolith",
        hp: [
            2, 4747, 5050,
        ],
    },
];

const dmg = [
    [5, 14.5, 17, 21, 25, 29],
    [10, 150, 180, 210, 240, 270, 320, 400, 480, 560, 600],
];

export { aura, hp, dmg };
